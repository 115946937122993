exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vsnB6-SVxODWlBJJIrOe3 {\n    margin-top: 40px;\n}", ""]);

// exports
exports.locals = {
	"supplier": "vsnB6-SVxODWlBJJIrOe3"
};