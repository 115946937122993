exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IrOZ25_hgjuekrouqnAx9 {\n    border-radius: 8pt;\n    box-shadow: 5px 5px 5px rgba(100, 100, 100, .5);\n    height: 120px;\n}\n\n._14sn2GCKOW-GkGJWhXM2lY {\n    font-size: 18px;\n}\n\n._3q1_QEP-29qspny9mRKIAr {\n    color: red;\n}\n\n._3yUjRtGJRVKSa_xLTHOMRw {\n    display: none;\n}\n\n._3qIOovKm328kYizA9qWVBX {\n    display: block;\n}", ""]);

// exports
exports.locals = {
	"iconImg": "IrOZ25_hgjuekrouqnAx9",
	"formLable": "_14sn2GCKOW-GkGJWhXM2lY",
	"required": "_3q1_QEP-29qspny9mRKIAr",
	"hideMsg": "_3yUjRtGJRVKSa_xLTHOMRw",
	"showMsg": "_3qIOovKm328kYizA9qWVBX"
};