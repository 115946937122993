exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2oSjBynOFJopcgAvxGmqjQ,\n._3PfgY5k1hKXZUyU6oIdikF {\n    margin-top: 0;\n}\n\n._1OCW-MHiMnf0wO5Zd_wah9 {\n    font-size: 9pt;\n    color: #999;\n}\n\n._1bMEqMDLGZKqjtkFIg-2yI {\n    border-radius: 8pt;\n    box-shadow: 5px 5px 5px rgba(100, 100, 100, 0.5);\n    height: 120px;\n}\n\n._1h76OjRXhtl_NmlJlNrWF4 {\n    color: #ff0000;\n}\n", ""]);

// exports
exports.locals = {
	"news_title": "_2oSjBynOFJopcgAvxGmqjQ",
	"news_paragraph": "_3PfgY5k1hKXZUyU6oIdikF",
	"news_last_update": "_1OCW-MHiMnf0wO5Zd_wah9",
	"iconImg": "_1bMEqMDLGZKqjtkFIg-2yI",
	"redText": "_1h76OjRXhtl_NmlJlNrWF4"
};