exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2D2KWNzaEb-vAlFm8o9pKK {\n    margin: 30px auto 10px auto;\n}\n.dYFLd8fPQROVbnWTE_paG {\n    margin: 0 auto 30px auto;\n    text-align: center;\n}\n._27YAIt1yKVQpVlO5AtTs5q {\n    margin: 30px auto 50px auto;\n    color: #999;\n}", ""]);

// exports
exports.locals = {
	"cardHeader": "_2D2KWNzaEb-vAlFm8o9pKK",
	"cardTitle": "dYFLd8fPQROVbnWTE_paG",
	"cardBody": "_27YAIt1yKVQpVlO5AtTs5q"
};